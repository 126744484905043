/* styles.css */
body {
  font: normal 14px/100% "Andale Mono", AndaleMono, monospace;
  color: #fff;
  padding: 50px;
  margin: 0 auto;
  background-color: #374954;
  text-align: center;
}


.bar {
  width: 10px;
  background-color: #fff;
  margin: 0 1px;
  display: inline-block;
}

button {
  display: inline;
  background-color: #6bbe92;
  border: 0;
  border-radius: 10px;
  box-shadow: 5px;
  margin: 10px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 30px;
}

button:hover {
  background-color: #579a77;
  cursor: pointer;
  color: #fff;
}
